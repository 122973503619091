import { NgModule }      from '@angular/core';
import { RegisterFormModelDirective } from './register-form-model.directive'
// import { AppRoutingModule }  from '../../modules/app-routing/app-routing.module';

@NgModule({
  declarations: [
    RegisterFormModelDirective
  ], exports: [
    RegisterFormModelDirective
  ]
})
export class RegisterFormModelModule { }
